var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popover-facture-avoir ml-1"},[_c('v-menu',{attrs:{"close-on-content-click":false,"right":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"block-icon-table-oblige-facture"},'div',attrs,false),on),[_c('font-awesome-icon',{class:{ 'margin-icon': _vm.content == 'num_lot' },attrs:{"icon":"info-circle"}})],1)]}}])},[_c('v-card',[_c('v-card-text',{staticClass:"body-card center-text"},[(_vm.content == 'num_lot')?_c('div',[_c('div',{staticClass:"flex-div font-size"},[_c('v-label',{staticClass:"title"},[_vm._v("Vendeur :")]),_vm._v(" "+_vm._s(_vm.data.name_vendeur)+" ")],1),_c('div',{staticClass:"flex-div font-size"},[_c('v-label',{staticClass:"title"},[_vm._v("Groupe :")]),_vm._v(" "+_vm._s(_vm.data.name_group)+" ")],1),_c('div',{staticClass:"flex-div font-size"},[_c('v-label',{staticClass:"title"},[_vm._v("Client :")]),_vm._v(" "+_vm._s(_vm.data.name_acheteur)+" ")],1)]):_vm._e(),(_vm.content == 'prime_ht')?_c('div',_vm._l((_vm.data.prime_factures),function(prime,i){return _c('div',{key:i,staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-space-between"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(prime.num)+", "+_vm._s(prime.total_ht)+" €")]),_c('template-obligee',{attrs:{"type":"prime","type2":"non-avoir","item":_vm.data,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.prime_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHVFP') &&
                    !_vm.data.email_sent_at &&
                    prime.FactureAvoir == 0,"updateFunction":_vm.uploadObligeeTemplateInServieVisionFiliale,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"downloadFactureApi":_vm.downloadFactureObligeeItem,"download":prime.id,"showDownloadButton":_vm.checkPermission('GAPTHTFPR'),"value":prime.total_ht,"can_reload":_vm.data.can_reload,"visialisation":_vm.checkPermission('GAPTHVFO'),"body":prime.body}}),_c('template-obligee',{attrs:{"type":"prime","type2":"avoir","item":_vm.data,"updateFunction":_vm.uploadObligeAvoir,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.prime_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHFAP') &&
                    prime.reste_avoir != 0,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"download":prime.id,"showDownloadButton":false,"value":prime.total_ht,"can_reload":_vm.data.can_reload,"IconClass":"red-color","filter":_vm.ComputedFilter}}),_c('Historique',{attrs:{"awesome":true,"permission":_vm.checkPermission('GAPTHAHPR'),"dataToUse":prime.logs,"index":prime.id,"oblige":true}})],1)])}),0):_vm._e(),(_vm.content == 'prime_avoir')?_c('div',[(_vm.data.prime_factures && _vm.data.prime_factures.length)?_c('div',{staticClass:"d-flex align-items-center mb-2"},_vm._l((_vm.data.prime_factures.filter(
                i => i.FactureAvoir.length
              )),function(fact,iF){return _c('span',{key:iF},[_c('TableFactureObligie',{attrs:{"table2":fact.FactureAvoir,"table1":fact,"visualisation":_vm.checkPermission('GAPTHVFAP'),"telecharger":_vm.checkPermission('GAPTHTPFAP')}})],1)}),0):_vm._e()]):_vm._e(),(_vm.content == 'missing_prime_ht')?_c('div',_vm._l((_vm.data.missing_prime_factures),function(mp,i){return _c('div',{key:i,staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-space-between"},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(mp.num)+", "+_vm._s(mp.total_ht)+" €")]),_c('template-obligee',{attrs:{"type":"missing_prime","type2":"non-avoir","item":_vm.data,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.missing_prime_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHVFI') &&
                    !_vm.data.email_sent_at &&
                    mp.FactureAvoir == 0,"updateFunction":_vm.uploadObligeeTemplateInServieVisionFiliale,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"downloadFactureApi":_vm.downloadFactureObligeeItem,"download":mp.id,"showDownloadButton":_vm.checkPermission('GAPTHTFIR'),"value":mp.total_ht,"can_reload":_vm.data.can_reload,"visialisation":_vm.checkPermission('GAPTHVFO'),"body":mp.body}}),_c('template-obligee',{attrs:{"type":"missing_prime","type2":"avoir","item":_vm.data,"updateFunction":_vm.uploadObligeAvoir,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.missing_prime_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHFAP') &&
                    mp.reste_avoir != 0,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"download":mp.id,"showDownloadButton":false,"value":mp.total_ht,"can_reload":_vm.data.can_reload,"IconClass":"red-color","filter":_vm.ComputedFilter}}),_c('Historique',{attrs:{"awesome":true,"permission":_vm.checkPermission('GAPTHAFIR'),"dataToUse":mp.logs,"index":mp.id,"oblige":true}})],1)])}),0):_vm._e(),(_vm.content == 'missing_prime_avoir')?_c('div',_vm._l((_vm.data.missing_prime_factures.filter(
              i => i.FactureAvoir.length
            )),function(fact,iF){return _c('span',{key:iF},[_c('TableFactureObligie',{attrs:{"table2":fact.FactureAvoir,"table1":fact,"visualisation":_vm.checkPermission('GAPTHVFAI'),"telecharger":_vm.checkPermission('GAPTHTPFAI')}})],1)}),0):_vm._e(),(_vm.content == 'commission_gestion_ht')?_c('div',_vm._l((_vm.data.commission_gestion_factures),function(commission_gestion,i){return _c('div',{key:i,staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-space-between"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(commission_gestion.num)+", "+_vm._s(commission_gestion.total_ht)+" €")]),(_vm.data.consolidee_energie_invest_factures.length == 0)?_c('template-obligee',{attrs:{"type":"commission_gestion","type2":"non-avoir","item":_vm.data,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.commission_gestion_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHVFCG') &&
                    !_vm.data.email_sent_at &&
                    commission_gestion.FactureAvoir == 0 &&
                    _vm.data.can_facture_commission_gestion,"updateFunction":_vm.uploadObligeeTemplateInServieVisionFiliale,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"downloadFactureApi":_vm.downloadFactureObligeeItem,"download":commission_gestion.id,"showDownloadButton":_vm.checkPermission('GAPTHTFCG'),"value":commission_gestion.total_ht,"can_reload":_vm.data.can_reload,"visialisation":_vm.checkPermission('GAPTHVFO'),"body":commission_gestion.body}}):_vm._e(),_c('template-obligee',{attrs:{"type":"commission_gestion","type2":"avoir","item":_vm.data,"updateFunction":_vm.uploadObligeAvoir,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.commission_gestion_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHFAP') &&
                    commission_gestion.reste_avoir != 0 &&
                    _vm.data.can_avoir_commission_gestion,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"download":commission_gestion.id,"showDownloadButton":false,"value":commission_gestion.total_ht,"can_reload":_vm.data.can_reload,"IconClass":"red-color","filter":_vm.ComputedFilter}}),_c('Historique',{attrs:{"awesome":true,"permission":_vm.checkPermission('GAPTHAHFCG'),"dataToUse":commission_gestion.logs,"index":commission_gestion.id,"oblige":true}})],1)])}),0):_vm._e(),(_vm.content == 'commission_gestion_avoir')?_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-inline-block"},_vm._l((_vm.data.commission_gestion_factures.filter(
                  i => i.FactureAvoir.length
                )),function(fact,iF){return _c('span',{key:iF},[_c('TableFactureObligie',{attrs:{"table2":fact.FactureAvoir,"table1":fact,"visualisation":_vm.checkPermission('GAPTHVFACG'),"telecharger":_vm.checkPermission('GAPTHTPFACG')}})],1)}),0)])]):_vm._e(),(_vm.content == 'commission_ht')?_c('div',_vm._l((_vm.data.commission_factures),function(commission,i){return _c('div',{key:i,staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-space-between"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(commission.num)+", "+_vm._s(commission.total_ht)+" €")]),(_vm.data.consolidee_energie_invest_factures.length == 0)?_c('template-obligee',{attrs:{"type":"commission","type2":"non-avoir","item":_vm.data,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.commission_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHVFC') &&
                    !_vm.data.email_sent_at &&
                    commission.FactureAvoir == 0 &&
                    _vm.data.can_facture_commission,"updateFunction":_vm.uploadObligeeTemplateInServieVisionFiliale,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"downloadFactureApi":_vm.downloadFactureObligeeItem,"download":commission.id,"showDownloadButton":_vm.checkPermission('GAPTHTFCR'),"value":commission.total_ht,"can_reload":_vm.data.can_reload,"visialisation":_vm.checkPermission('GAPTHVFO'),"body":commission.body}}):_vm._e(),_c('template-obligee',{attrs:{"type":"commission","type2":"avoir","item":_vm.data,"updateFunction":_vm.uploadObligeAvoir,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.commission_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHGFAC') &&
                    commission.reste_avoir != 0 &&
                    _vm.data.can_avoir_commission,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"download":commission.id,"showDownloadButton":false,"value":commission.total_ht,"can_reload":_vm.data.can_reload,"IconClass":"red-color","filter":_vm.ComputedFilter}}),_c('Historique',{attrs:{"awesome":true,"permission":_vm.checkPermission('GAPTHAHFCR'),"dataToUse":commission.logs,"index":commission.id,"oblige":true}})],1)])}),0):_vm._e(),(_vm.content == 'commission_avoir')?_c('div',[_c('div',{staticClass:"d-inline-block"},_vm._l((_vm.data.commission_factures.filter(
                i => i.FactureAvoir.length
              )),function(fact,iF){return _c('span',{key:iF},[_c('TableFactureObligie',{attrs:{"table2":fact.FactureAvoir,"table1":fact,"visualisation":_vm.checkPermission('GAPTHVFAC'),"telecharger":_vm.checkPermission('GAPTHTPFAC')}})],1)}),0)]):_vm._e(),(_vm.content == 'surprime_ht')?_c('div',_vm._l((_vm.data.surprime_factures),function(surprime,i){return _c('div',{key:i},[_c('div',{staticClass:"d-flex justify-content-space-between mb-2"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(surprime.num)+", "+_vm._s(surprime.total_ht)+" €")]),(_vm.data.consolidee_energie_invest_factures.length == 0)?_c('template-obligee',{attrs:{"type":"surprime","type2":"non-avoir","item":_vm.data,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.surprime_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHVFS') &&
                    !_vm.data.email_sent_at &&
                    surprime.FactureAvoir == 0 &&
                    _vm.data.can_facture_surprime,"updateFunction":_vm.uploadObligeeTemplateInServieVisionFiliale,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"downloadFactureApi":_vm.downloadFactureObligeeItem,"download":surprime.id,"showDownloadButton":_vm.checkPermission('GAPTHTFSR'),"value":surprime.total_ht,"can_reload":_vm.data.can_reload,"visialisation":_vm.checkPermission('GAPTHVFO'),"body":surprime.body}}):_vm._e(),_c('template-obligee',{attrs:{"type":"surprime","type2":"avoir","item":_vm.data,"updateFunction":_vm.uploadObligeAvoir,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.surprime_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHGFAS') &&
                    surprime.reste_avoir != 0 &&
                    _vm.data.can_avoir_surprime,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"download":surprime.id,"showDownloadButton":false,"value":surprime.total_ht,"can_reload":_vm.data.can_reload,"IconClass":"red-color","filter":_vm.ComputedFilter}}),_c('Historique',{attrs:{"awesome":true,"permission":_vm.checkPermission('GAPTHAHFSR'),"dataToUse":surprime.logs,"index":surprime.id,"oblige":true}})],1)])}),0):_vm._e(),(_vm.content == 'surprime_avoir')?_c('div',[_c('div',{staticClass:"d-inline-block"}),_vm._l((_vm.data.surprime_factures.filter(
              i => i.FactureAvoir.length
            )),function(fact,iF){return _c('span',{key:iF},[_c('TableFactureObligie',{attrs:{"table2":fact.FactureAvoir,"table1":fact,"visualisation":_vm.checkPermission('GAPTHVFAS'),"telecharger":_vm.checkPermission('GAPTHTPFAS')}})],1)})],2):_vm._e(),(_vm.content == 'consolidee_energie_invest_ht')?_c('div',_vm._l((_vm.data.consolidee_energie_invest_factures),function(consolidee_energie_invest,i){return _c('div',{key:i},[_c('div',{staticClass:"d-flex justify-content-space-between mb-2"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(consolidee_energie_invest.num)+", "+_vm._s(consolidee_energie_invest.total_ht)+" €")]),_c('template-obligee',{attrs:{"type":"consolidee_energie_invest","type2":"non-avoir","item":_vm.data,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.consolidee_energie_invest_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHVFCEI') &&
                    !_vm.data.email_sent_at &&
                    consolidee_energie_invest.FactureAvoir == 0 &&
                    _vm.data.can_facture_consolidee_energie_invest,"updateFunction":_vm.uploadObligeeTemplateInServieVisionFiliale,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"downloadFactureApi":_vm.downloadFactureObligeeItem,"download":consolidee_energie_invest.id,"showDownloadButton":_vm.checkPermission('GAPTHTFCEI'),"value":consolidee_energie_invest.total_ht,"can_reload":_vm.data.can_reload,"visialisation":_vm.checkPermission('GAPTHVFO'),"body":consolidee_energie_invest.body}}),_c('template-obligee',{attrs:{"type":"consolidee_energie_invest","type2":"avoir","item":_vm.data,"updateFunction":_vm.uploadObligeAvoir,"show_generate_facture_file_code":!_vm.data.error &&
                    _vm.data.consolidee_energie_invest_statut != 'Payé' &&
                    _vm.checkPermission('GAPTHTPFACEI') &&
                    consolidee_energie_invest.reste_avoir != 0 &&
                    _vm.data.can_avoir_consolidee_energie_invest,"getTemplateFunction":_vm.getObligeeTemplateAndFiledsDataToUpdateForFcature,"refereshTemplateFunction":_vm.refreshFactureDossier,"download":consolidee_energie_invest.id,"showDownloadButton":false,"value":consolidee_energie_invest.total_ht,"can_reload":_vm.data.can_reload,"IconClass":"red-color","filter":_vm.ComputedFilter}}),_c('Historique',{attrs:{"awesome":true,"permission":_vm.checkPermission('GAPTHAHCEI'),"dataToUse":consolidee_energie_invest.logs,"index":consolidee_energie_invest.id,"oblige":true}})],1)])}),0):_vm._e(),(_vm.content == 'consolidee_energie_invest_avoir')?_c('div',[_c('div',{staticClass:"d-inline-block"},_vm._l((_vm.data.consolidee_energie_invest_factures.filter(
                i => i.FactureAvoir.length
              )),function(fact,iF){return _c('span',{key:iF},[_c('TableFactureObligie',{attrs:{"table2":fact.FactureAvoir,"table1":fact,"visualisation":_vm.checkPermission('GAPTHVFACEI'),"telecharger":_vm.checkPermission('GAPTHTPFACEI')}})],1)}),0)]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }