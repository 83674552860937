<template>
  <div class="popover-facture-avoir ml-1">
    <v-menu :close-on-content-click="false" right offset-x>
      <template v-slot:activator="{ on, attrs }">
        <div class="block-icon-table-oblige-facture" v-on="on" v-bind="attrs">
          <font-awesome-icon
            icon="info-circle"
            :class="{ 'margin-icon': content == 'num_lot' }"
          />
        </div>
      </template>
      <v-card>
        <v-card-text class="body-card center-text">
          <div v-if="content == 'num_lot'">
            <div class="flex-div font-size">
              <v-label class="title">Vendeur :</v-label>
              {{ data.name_vendeur }}
            </div>
            <div class="flex-div font-size">
              <v-label class="title">Groupe :</v-label>
              {{ data.name_group }}
            </div>
            <div class="flex-div font-size">
              <v-label class="title">Client :</v-label>
              {{ data.name_acheteur }}
            </div>
          </div>
          <div v-if="content == 'prime_ht'">
            <div
              v-for="(prime, i) in data.prime_factures"
              :key="i"
              class="mb-2"
            >
              <div class="d-flex justify-content-space-between">
                <span class="mr-2"
                  >{{ prime.num }}, {{ prime.total_ht }} €</span
                >
                <template-obligee
                  type="prime"
                  type2="non-avoir"
                  :item="data"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.prime_statut != 'Payé' &&
                      checkPermission('GAPTHVFP') &&
                      !data.email_sent_at &&
                      prime.FactureAvoir == 0
                  "
                  :updateFunction="uploadObligeeTemplateInServieVisionFiliale"
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :downloadFactureApi="downloadFactureObligeeItem"
                  :download="prime.id"
                  :showDownloadButton="checkPermission('GAPTHTFPR')"
                  :value="prime.total_ht"
                  :can_reload="data.can_reload"
                  :visialisation="checkPermission('GAPTHVFO')"
                  :body="prime.body"
                />
                <template-obligee
                  type="prime"
                  type2="avoir"
                  :item="data"
                  :updateFunction="uploadObligeAvoir"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.prime_statut != 'Payé' &&
                      checkPermission('GAPTHFAP') &&
                      prime.reste_avoir != 0
                  "
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :download="prime.id"
                  :showDownloadButton="false"
                  :value="prime.total_ht"
                  :can_reload="data.can_reload"
                  IconClass="red-color"
                  :filter="ComputedFilter"
                />
                <Historique
                  :awesome="true"
                  :permission="checkPermission('GAPTHAHPR')"
                  :dataToUse="prime.logs"
                  :index="prime.id"
                  :oblige="true"
                />
              </div>
            </div>
          </div>
          <div v-if="content == 'prime_avoir'">
            <div
              class="d-flex align-items-center mb-2"
              v-if="data.prime_factures && data.prime_factures.length"
            >
              <span
                v-for="(fact, iF) in data.prime_factures.filter(
                  i => i.FactureAvoir.length
                )"
                :key="iF"
              >
                <TableFactureObligie
                  :table2="fact.FactureAvoir"
                  :table1="fact"
                  :visualisation="checkPermission('GAPTHVFAP')"
                  :telecharger="checkPermission('GAPTHTPFAP')"
                ></TableFactureObligie>
              </span>
            </div>
          </div>
          <div v-if="content == 'missing_prime_ht'">
            <div
              v-for="(mp, i) in data.missing_prime_factures"
              :key="i"
              class="mb-2"
            >
              <div class="d-flex justify-content-space-between">
                <span class="mr-2"> {{ mp.num }}, {{ mp.total_ht }} €</span>
                <template-obligee
                  type="missing_prime"
                  type2="non-avoir"
                  :item="data"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.missing_prime_statut != 'Payé' &&
                      checkPermission('GAPTHVFI') &&
                      !data.email_sent_at &&
                      mp.FactureAvoir == 0
                  "
                  :updateFunction="uploadObligeeTemplateInServieVisionFiliale"
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :downloadFactureApi="downloadFactureObligeeItem"
                  :download="mp.id"
                  :showDownloadButton="checkPermission('GAPTHTFIR')"
                  :value="mp.total_ht"
                  :can_reload="data.can_reload"
                  :visialisation="checkPermission('GAPTHVFO')"
                  :body="mp.body"
                />
                <template-obligee
                  type="missing_prime"
                  type2="avoir"
                  :item="data"
                  :updateFunction="uploadObligeAvoir"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.missing_prime_statut != 'Payé' &&
                      checkPermission('GAPTHFAP') &&
                      mp.reste_avoir != 0
                  "
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :download="mp.id"
                  :showDownloadButton="false"
                  :value="mp.total_ht"
                  :can_reload="data.can_reload"
                  IconClass="red-color"
                  :filter="ComputedFilter"
                />
                <Historique
                  :awesome="true"
                  :permission="checkPermission('GAPTHAFIR')"
                  :dataToUse="mp.logs"
                  :index="mp.id"
                  :oblige="true"
                />
              </div>
            </div>
          </div>
          <div v-if="content == 'missing_prime_avoir'">
            <span
              v-for="(fact, iF) in data.missing_prime_factures.filter(
                i => i.FactureAvoir.length
              )"
              :key="iF"
            >
              <TableFactureObligie
                :table2="fact.FactureAvoir"
                :table1="fact"
                :visualisation="checkPermission('GAPTHVFAI')"
                :telecharger="checkPermission('GAPTHTPFAI')"
              ></TableFactureObligie>
            </span>
          </div>
          <div v-if="content == 'commission_gestion_ht'">
            <div
              class="mb-2"
              v-for="(commission_gestion,
              i) in data.commission_gestion_factures"
              :key="i"
            >
              <div class="d-flex justify-content-space-between">
                <span class="mr-2"
                  >{{ commission_gestion.num }},
                  {{ commission_gestion.total_ht }} €</span
                >
                <template-obligee
                  v-if="data.consolidee_energie_invest_factures.length == 0"
                  type="commission_gestion"
                  type2="non-avoir"
                  :item="data"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.commission_gestion_statut != 'Payé' &&
                      checkPermission('GAPTHVFCG') &&
                      !data.email_sent_at &&
                      commission_gestion.FactureAvoir == 0 &&
                      data.can_facture_commission_gestion
                  "
                  :updateFunction="uploadObligeeTemplateInServieVisionFiliale"
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :downloadFactureApi="downloadFactureObligeeItem"
                  :download="commission_gestion.id"
                  :showDownloadButton="checkPermission('GAPTHTFCG')"
                  :value="commission_gestion.total_ht"
                  :can_reload="data.can_reload"
                  :visialisation="checkPermission('GAPTHVFO')"
                  :body="commission_gestion.body"
                />
                <template-obligee
                  type="commission_gestion"
                  type2="avoir"
                  :item="data"
                  :updateFunction="uploadObligeAvoir"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.commission_gestion_statut != 'Payé' &&
                      checkPermission('GAPTHFAP') &&
                      commission_gestion.reste_avoir != 0 &&
                      data.can_avoir_commission_gestion
                  "
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :download="commission_gestion.id"
                  :showDownloadButton="false"
                  :value="commission_gestion.total_ht"
                  :can_reload="data.can_reload"
                  IconClass="red-color"
                  :filter="ComputedFilter"
                />
                <Historique
                  :awesome="true"
                  :permission="checkPermission('GAPTHAHFCG')"
                  :dataToUse="commission_gestion.logs"
                  :index="commission_gestion.id"
                  :oblige="true"
                />
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div v-if="content == 'commission_gestion_avoir'">
            <div class="d-flex align-items-center">
              <div class="d-inline-block">
                <span
                  v-for="(fact, iF) in data.commission_gestion_factures.filter(
                    i => i.FactureAvoir.length
                  )"
                  :key="iF"
                >
                  <TableFactureObligie
                    :table2="fact.FactureAvoir"
                    :table1="fact"
                    :visualisation="checkPermission('GAPTHVFACG')"
                    :telecharger="checkPermission('GAPTHTPFACG')"
                  ></TableFactureObligie>
                </span>
              </div>
            </div>
          </div>
          <div v-if="content == 'commission_ht'">
            <div
              v-for="(commission, i) in data.commission_factures"
              :key="i"
              class="mb-2"
            >
              <div class="d-flex justify-content-space-between">
                <span class="mr-2"
                  >{{ commission.num }}, {{ commission.total_ht }} €</span
                >
                <template-obligee
                  v-if="data.consolidee_energie_invest_factures.length == 0"
                  type="commission"
                  type2="non-avoir"
                  :item="data"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.commission_statut != 'Payé' &&
                      checkPermission('GAPTHVFC') &&
                      !data.email_sent_at &&
                      commission.FactureAvoir == 0 &&
                      data.can_facture_commission
                  "
                  :updateFunction="uploadObligeeTemplateInServieVisionFiliale"
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :downloadFactureApi="downloadFactureObligeeItem"
                  :download="commission.id"
                  :showDownloadButton="checkPermission('GAPTHTFCR')"
                  :value="commission.total_ht"
                  :can_reload="data.can_reload"
                  :visialisation="checkPermission('GAPTHVFO')"
                  :body="commission.body"
                />
                <template-obligee
                  type="commission"
                  type2="avoir"
                  :item="data"
                  :updateFunction="uploadObligeAvoir"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.commission_statut != 'Payé' &&
                      checkPermission('GAPTHGFAC') &&
                      commission.reste_avoir != 0 &&
                      data.can_avoir_commission
                  "
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :download="commission.id"
                  :showDownloadButton="false"
                  :value="commission.total_ht"
                  :can_reload="data.can_reload"
                  IconClass="red-color"
                  :filter="ComputedFilter"
                />
                <Historique
                  :awesome="true"
                  :permission="checkPermission('GAPTHAHFCR')"
                  :dataToUse="commission.logs"
                  :index="commission.id"
                  :oblige="true"
                />
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div v-if="content == 'commission_avoir'">
            <div class="d-inline-block">
              <span
                v-for="(fact, iF) in data.commission_factures.filter(
                  i => i.FactureAvoir.length
                )"
                :key="iF"
              >
                <TableFactureObligie
                  :table2="fact.FactureAvoir"
                  :table1="fact"
                  :visualisation="checkPermission('GAPTHVFAC')"
                  :telecharger="checkPermission('GAPTHTPFAC')"
                ></TableFactureObligie>
              </span>
            </div>
          </div>
          <div v-if="content == 'surprime_ht'">
            <div v-for="(surprime, i) in data.surprime_factures" :key="i">
              <div class="d-flex justify-content-space-between mb-2">
                <span class="mr-2"
                  >{{ surprime.num }}, {{ surprime.total_ht }} €</span
                >
                <template-obligee
                  v-if="data.consolidee_energie_invest_factures.length == 0"
                  type="surprime"
                  type2="non-avoir"
                  :item="data"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.surprime_statut != 'Payé' &&
                      checkPermission('GAPTHVFS') &&
                      !data.email_sent_at &&
                      surprime.FactureAvoir == 0 &&
                      data.can_facture_surprime
                  "
                  :updateFunction="uploadObligeeTemplateInServieVisionFiliale"
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :downloadFactureApi="downloadFactureObligeeItem"
                  :download="surprime.id"
                  :showDownloadButton="checkPermission('GAPTHTFSR')"
                  :value="surprime.total_ht"
                  :can_reload="data.can_reload"
                  :visialisation="checkPermission('GAPTHVFO')"
                  :body="surprime.body"
                />
                <template-obligee
                  type="surprime"
                  type2="avoir"
                  :item="data"
                  :updateFunction="uploadObligeAvoir"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.surprime_statut != 'Payé' &&
                      checkPermission('GAPTHGFAS') &&
                      surprime.reste_avoir != 0 &&
                      data.can_avoir_surprime
                  "
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :download="surprime.id"
                  :showDownloadButton="false"
                  :value="surprime.total_ht"
                  :can_reload="data.can_reload"
                  IconClass="red-color"
                  :filter="ComputedFilter"
                />
                <Historique
                  :awesome="true"
                  :permission="checkPermission('GAPTHAHFSR')"
                  :dataToUse="surprime.logs"
                  :index="surprime.id"
                  :oblige="true"
                />
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div v-if="content == 'surprime_avoir'">
            <div class="d-inline-block"></div>
            <span
              v-for="(fact, iF) in data.surprime_factures.filter(
                i => i.FactureAvoir.length
              )"
              :key="iF"
            >
              <TableFactureObligie
                :table2="fact.FactureAvoir"
                :table1="fact"
                :visualisation="checkPermission('GAPTHVFAS')"
                :telecharger="checkPermission('GAPTHTPFAS')"
              ></TableFactureObligie>
            </span>
          </div>
          <div v-if="content == 'consolidee_energie_invest_ht'">
            <div
              v-for="(consolidee_energie_invest,
              i) in data.consolidee_energie_invest_factures"
              :key="i"
            >
              <div class="d-flex justify-content-space-between mb-2">
                <span class="mr-2"
                  >{{ consolidee_energie_invest.num }},
                  {{ consolidee_energie_invest.total_ht }}
                  €</span
                >
                <template-obligee
                  type="consolidee_energie_invest"
                  type2="non-avoir"
                  :item="data"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.consolidee_energie_invest_statut != 'Payé' &&
                      checkPermission('GAPTHVFCEI') &&
                      !data.email_sent_at &&
                      consolidee_energie_invest.FactureAvoir == 0 &&
                      data.can_facture_consolidee_energie_invest
                  "
                  :updateFunction="uploadObligeeTemplateInServieVisionFiliale"
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :downloadFactureApi="downloadFactureObligeeItem"
                  :download="consolidee_energie_invest.id"
                  :showDownloadButton="checkPermission('GAPTHTFCEI')"
                  :value="consolidee_energie_invest.total_ht"
                  :can_reload="data.can_reload"
                  :visialisation="checkPermission('GAPTHVFO')"
                  :body="consolidee_energie_invest.body"
                />
                <template-obligee
                  type="consolidee_energie_invest"
                  type2="avoir"
                  :item="data"
                  :updateFunction="uploadObligeAvoir"
                  :show_generate_facture_file_code="
                    !data.error &&
                      data.consolidee_energie_invest_statut != 'Payé' &&
                      checkPermission('GAPTHTPFACEI') &&
                      consolidee_energie_invest.reste_avoir != 0 &&
                      data.can_avoir_consolidee_energie_invest
                  "
                  :getTemplateFunction="
                    getObligeeTemplateAndFiledsDataToUpdateForFcature
                  "
                  :refereshTemplateFunction="refreshFactureDossier"
                  :download="consolidee_energie_invest.id"
                  :showDownloadButton="false"
                  :value="consolidee_energie_invest.total_ht"
                  :can_reload="data.can_reload"
                  IconClass="red-color"
                  :filter="ComputedFilter"
                />
                <Historique
                  :awesome="true"
                  :permission="checkPermission('GAPTHAHCEI')"
                  :dataToUse="consolidee_energie_invest.logs"
                  :index="consolidee_energie_invest.id"
                  :oblige="true"
                />
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div v-if="content == 'consolidee_energie_invest_avoir'">
            <div class="d-inline-block">
              <span
                v-for="(fact,
                iF) in data.consolidee_energie_invest_factures.filter(
                  i => i.FactureAvoir.length
                )"
                :key="iF"
              >
                <TableFactureObligie
                  :table2="fact.FactureAvoir"
                  :table1="fact"
                  :visualisation="checkPermission('GAPTHVFACEI')"
                  :telecharger="checkPermission('GAPTHTPFACEI')"
                ></TableFactureObligie>
              </span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    content: { required: true },
    data: { required: true }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['checkPermission']),
    ComputedFilter() {
      return {
        filterOrganisme: this.filterOrganisme,
        filterFiliale: this.filterFiliale,
        filterLot: this.filterLot,
        num: this.searchValue,
        date_debut: this.date_debut,
        date_fin: this.date_fin
      };
    }
  },
  mounted() {},
  methods: {
    ...mapActions([
      'downloadFactureObligeeItem',
      'uploadObligeeTemplateInServieVisionFiliale',
      'getObligeeTemplateAndFiledsDataToUpdateForFcature',
      'refreshFactureDossier',
      'getObligeeTemplateAndFiledsDataToUpdateForFcature',
      'uploadObligeAvoir'
    ])
  },
  components: {
    Historique: () => import('@/views/component/Historique.vue'),
    TableFactureObligie: () =>
      import('@/views/component/Facture/TableFactureObligie.vue'),
    TemplateObligee: () =>
      import('@/views/ThProject/oblige/component/templateObligee.vue')
  }
};
</script>
<style scoped>
.margin-icon {
  margin-top: 2px;
}
.title {
  font-weight: 700;
  color: #704ad1;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px !important;
}
.font-size {
  font-size: 12px !important;
}
</style>
